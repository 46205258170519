.js input.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

input.inputfile + label {
    max-width: 80%;
    font-size:  0.8777em;
    /* 20px */
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
}

.no-js input.inputfile + label {
    display: none;
}

input.inputfile:focus + label,
input.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

input.inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}

input.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}


/* style 1 */

input.inputfile-1 + label {
    color: #f1e5e6;
    background-color: #d3394c;
}

input.inputfile-1:focus + label,
input.inputfile-1.has-focus + label,
input.inputfile-1 + label:hover {
    background-color: #722040;
}


/* style 2 */

input.inputfile-2 + label {
    color: #d3394c;
    border: 2px solid currentColor;
}

input.inputfile-2:focus + label,
input.inputfile-2.has-focus + label,
input.inputfile-2 + label:hover {
    color: #722040;
}


/* style 3 */

input.inputfile-3 + label {
    color: #d3394c;
}

input.inputfile-3:focus + label,
input.inputfile-3.has-focus + label,
input.inputfile-3 + label:hover {
    color: #722040;
}


/* style 4 */

input.inputfile-4 + label {
    color: #d3394c;
}

input.inputfile-4:focus + label,
input.inputfile-4.has-focus + label,
input.inputfile-4 + label:hover {
    color: #722040;
}

input.inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
}

input.inputfile-4:focus + label figure,
input.inputfile-4.has-focus + label figure,
input.inputfile-4 + label:hover figure {
    background-color: #722040;
}

input.inputfile-4 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
}


/* style 5 */

input.inputfile-5 + label {
    color: #d3394c;
}

input.inputfile-5:focus + label,
input.inputfile-5.has-focus + label,
input.inputfile-5 + label:hover {
    color: #722040;
}

input.inputfile-5 + label figure {
    width: 100px;
    height: 135px;
    background-color: #d3394c;
    display: block;
    position: relative;
    padding: 30px;
    margin: 0 auto 10px;
}

input.inputfile-5:focus + label figure,
input.inputfile-5.has-focus + label figure,
input.inputfile-5 + label:hover figure {
    background-color: #722040;
}

input.inputfile-5 + label figure::before,
input.inputfile-5 + label figure::after {
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
}

input.inputfile-5 + label figure::before {
    border-top: 20px solid #dfc8ca;
    border-left: 20px solid transparent;
}

input.inputfile-5 + label figure::after {
    border-bottom: 20px solid #722040;
    border-right: 20px solid transparent;
}

input.inputfile-5:focus + label figure::after,
input.inputfile-5.has-focus + label figure::after,
input.inputfile-5 + label:hover figure::after {
    border-bottom-color: #d3394c;
}

input.inputfile-5 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
}


/* style 6 */

input.inputfile-6 + label {
    color: #d3394c;
}

input.inputfile-6 + label {
    border: 1px solid #d3394c;
    background-color: #f1e5e6;
    padding: 0;
}

input.inputfile-6:focus + label,
input.inputfile-6.has-focus + label,
input.inputfile-6 + label:hover {
    border-color: #722040;
}

input.inputfile-6 + label span,
input.inputfile-6 + label strong {
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
}

input.inputfile-6 + label span {
    width: 200px;
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
}

input.inputfile-6 + label strong {
    height: 100%;
    color: #f1e5e6;
    background-color: #d3394c;
    display: inline-block;
}

input.inputfile-6:focus + label strong,
input.inputfile-6.has-focus + label strong,
input.inputfile-6 + label:hover strong {
    background-color: #722040;
}

@media screen and (max-width: 50em) {
	input.inputfile-6 + label strong {
		display: block;
	}
}
